
import { School } from "@/entities/school";
import { getSchools } from "@/api/school";
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import dayjs from "dayjs";

@Options({})
export default class SchoolAIMigration extends Vue {
  schools: School[] = [];
  selectedSchools: School[] = [];
  adminKey = "";
  target = "";
  loading = false;
  completedCount = 0;

  get selectedSchoolIds(): string[] {
    return this.selectedSchools.map(s => s.ref.id);
  }

  handleChange(e: Event, school: School) {
    if ((e.target as HTMLInputElement).checked) {
      if (this.selectedSchoolIds.includes(school.ref.id)) return;
      this.selectedSchools.push(school);
    } else {
      this.selectedSchools = this.selectedSchools.filter(
        s => s.ref.id !== school.ref.id
      );
    }
  }

  toggleSchoolCheckboxes(val: boolean) {
    const schoolsContainer = this.$refs[
      "schools-container"
    ] as HTMLElement | null;
    if (schoolsContainer) {
      const checkboxes = schoolsContainer.querySelectorAll(
        'input[type="checkbox"]'
      );
      checkboxes.forEach(checkbox => {
        (checkbox as HTMLInputElement).checked = val;
      });
    }
  }

  enableSelectedCheckBox() {
    const schoolsContainer = this.$refs[
      "schools-container"
    ] as HTMLElement | null;
    if (schoolsContainer) {
      const checkboxes = schoolsContainer.querySelectorAll(
        'input[type="checkbox"]'
      );
      this.schools.forEach((school, index) => {
        if (this.selectedSchools.some(s => s.ref.id === school.ref.id)) {
          (checkboxes[index] as HTMLInputElement).checked = true;
        } else {
          (checkboxes[index] as HTMLInputElement).checked = false;
        }
      });
    }
  }

  selectAll() {
    this.toggleSchoolCheckboxes(true);
    this.selectedSchools = [...this.schools];
  }

  unselectAll() {
    this.toggleSchoolCheckboxes(false);
    this.selectedSchools = [];
  }

  async getUnmigratedSchools() {
    const today = dayjs();
    const targetSchools = this.schools.filter(
      s =>
        !s.data.promptMigrated ||
        (s.data.promptMigrated &&
          s.data.promptMigratedAt &&
          dayjs.unix(s.data.promptMigratedAt).isBefore(today, "day"))
    );

    const message = `
${targetSchools.length}件

${targetSchools.map(s => s.data.name).join("\n")}
`;
    alert(message);

    if (window.confirm("これらのスクールをすべて選択しますか？")) {
      this.selectedSchools = [...targetSchools];
      this.enableSelectedCheckBox();
    }
  }

  async execute() {
    if (!this.adminKey) {
      alert("キーを入れてください");
      return;
    }
    if (!this.target) {
      alert("対象を入力してください");
      return;
    }
    if (this.selectedSchools.length === 0) {
      alert("学校が選択されていません。");
      return;
    }
    if (this.loading) return;

    if (!window.confirm("実行して良いですか？")) return;

    let url = "";
    const hostname = window.location.hostname;
    if (hostname === "admin.mingaku-dev-j82s7q6.com") {
      url =
        "https://asia-northeast1-mingaku-jp-dev.cloudfunctions.net/migrate_school_ai_data";
    } else if (hostname === "admin.mingaku-stg-hhgg9se.com") {
      url =
        "https://asia-northeast1-mingaku-st.cloudfunctions.net/migrate_school_ai_data";
    } else if (hostname === "admin.mingaku.net") {
      url =
        "https://asia-northeast1-mingaku-jp.cloudfunctions.net/migrate_school_ai_data";
    }

    if (!url) {
      alert("この操作を行うことができません。");
      return;
    }

    try {
      this.loading = true;
      const failures: { name: string; id: string; reason: string }[] = [];
      let successCount = 0;

      for (let i = 0; i < this.selectedSchoolIds.length; i++) {
        const id = this.selectedSchoolIds[i];
        try {
          await axios.post(
            url,
            { schoolId: id, migrationTargets: [this.target] },
            {
              headers: {
                "Content-Type": "application/json",
                "api-key": this.adminKey
              }
            }
          );
          successCount++;
        } catch (e) {
          console.error(e);
          failures.push({
            name: this.selectedSchools[i].data.name,
            id: this.selectedSchools[i].ref.id,
            reason:
              e instanceof Error
                ? e.message ?? "unknown error"
                : "unknown error"
          });
        } finally {
          this.completedCount++;
        }
      }

      const text = `
成功件数 : ${successCount}
失敗件数 : ${failures.length}

【失敗詳細】
${failures
  .map(failure => `${failure.name}(${failure.id}) : ${failure.reason}`)
  .join("\n")}
`.trim();

      alert(text);
    } catch (e) {
      alert("失敗しました。");
    } finally {
      this.loading = false;
    }
  }

  async created() {
    this.schools = await getSchools();
  }
}
